body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #FFF;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

body {
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #FFF;
}

@font-face {
  font-family: 'Quarca';
  font-style: normal;
  font-weight: 800;
  src: local('Quarca-Ext-Regular'),
       local('Quarca Ext Regular'),
       url(/static/media/QuarcaExtRegular.7d53093d.otf) format('opentype'); /* Modern Browsers */
}

@font-face {
  font-family: 'Quarca-Light';
  font-style: normal;
  font-weight: 800;
  src: local('Quarca-Ext-Light'),
       local('Quarca Ext Light'),
       url(/static/media/QuarcaNormRegular.3bb7fb2a.otf) format('opentype'); /* Modern Browsers */
}

@font-face {
  font-family: 'Quarca-Fat';
  font-style: normal;
  font-weight: 800;
  src: local('Quarca-Ext-Fat'),
       local('Quarca Ext Fat'),
       url(/static/media/QuarcaNormMedium.a81408df.otf) format('opentype'); /* Modern Browsers */
}

@font-face {
  font-family: 'Perfect Dark';
  font-style: normal;
  font-weight: 900;
  src: local('Perfect-Dark'),
       local('Perfect Dark'),
       url(/static/media/pdark2.8cb9ec4e.ttf) format('truetype'); /* Modern Browsers */
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media only screen and (min-width: 1600px) {
  .nice-button-wrapper {
    font-size: 25px !important;
  }

  .nice-button {
    background: #FFF;
    padding: 20px !important;
    transition: 500ms all ease-in-out;
    width: 140px !important;
  }

  .nice-button-overlay {
    width: 140px !important;
  }

  .topBar {
    height: 140px !important;
  }
  .topBar:not(.hidebar){
    height: 110px !important;
  }
}

.nice-button-wrapper {
  padding: 1px;
  background-image: linear-gradient(22deg, #0FF, #57F);
  border: none;
  color: #555;
  display: grid;
  place-items: center;
  font-family: 'Quarca', sans-serif;
  margin-right: 48px;
  font-size: 18px;
}

.nice-button {
  background: #FFF;
  padding-top: 10px;
  padding-bottom: 10px;
    transition: 500ms all ease-in-out;
  width: 110px;
}

.nice-button-text {
  background: -webkit-linear-gradient(22deg, #0FF, #57F);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 500ms all ease-in-out;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.nice-button:hover {
  background: none;
  color: #FFF;
}

.nice-button:hover .nice-button-overlay {
  opacity: 1;
}

.nice-button-overlay {
  position: absolute;
  color: #FFF;
  opacity: 0;
  cursor: default;
  transition: 500ms all ease-in-out;
  width: 110px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.topBar{
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background: #FFFD;
  width: 100%;
  height: 118px;
  margin-top: -1px;
  position: fixed;
  z-index: 9999;
  top: 0;
  transition: all 500ms ease-in-out;
}

.topBar:hover:not(.hidebar) {
  background: #FFFF;
}

.topBar:not(.hidebar){
  height: 90px;
}

.repairSection{
  position:absolute;
  margin-top:-140px;
}

.topBar a {
  text-decoration: none;
  cursor: default;
}

.hidebar{
  background: none;
  opacity: 0.7
}

.hidebar .nice-button{
  background: none;
}
.hidebar .nice-button-wrapper{
  background: none;
}

.hidebar .nice-button-wrapper:hover .nice-button-overlay{
  text-shadow: 0px 0px 10px #3AF;
}


.hidebar:hover{
  opacity: 1;
}


.buttonsContainer {
  display: -webkit-flex;
  display: flex;
}

.logo {
  font-family: 'Perfect Dark', sans-serif;
  font-size: 2em;
  -webkit-font-feature-settings: "kern" off;
          font-feature-settings: "kern" off;
  -webkit-font-kerning: none;
          font-kerning: none;
  margin-left: 40px;
  color: #3AF;
  /* text-shadow: 0px 0px 50px #AAF; */
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: all 300ms ease-in-out;
}

.hidebar .logo {
  margin-top: -15px;
}

.logo:hover {
  color: #FFF;
  text-shadow: 0px 0px 30px #3AF;
}

.slide {
  width: 100%;
}

.carousel{
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.carButtons {
  font-family: 'Quarca', sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  z-index: 999;
  position: absolute;
  width: 100%;
  color: #FFF;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.carbut {
  width: 2em;
  height: 2em;
  font-size: 2em;
  display: -webkit-flex !important;
  display: flex !important;
  text-align: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 3%;
  margin-right: 3%;
  opacity: 0.5;
  transition: all 300ms ease-in-out;
}

.carbut:hover {
  opacity: 1;
}

.flip {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.pic {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.inspic {
  width: 100%;
}

.plane-text {
  padding: 10%;
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
}

.plane{
  width: 100%;
  margin-top: 150px;
  font-family: "Quarca", sans-serif;
}

.maxiplane{
  transition: all 500ms ease-in-out;
  width: 100%;
}

.plane .title{
  font-size: 1.5em;
  display: -webkit-flex;
  display: flex;
  margin-top: -20px;
  -webkit-justify-content: center;
          justify-content: center;
}

.plane .subtitle{
  font-family: 'Quarca-Fat', sans-serif;
  font-size: 0.9em;
  padding-bottom: 60px;
}

.more-button-wrapper{
  display: -webkit-flex;
  display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.more-button {
  transition: all 300ms ease-in-out;
  width:10%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.more-text-wrapper {
  overflow: hidden;
  position: absolute;
  height: 0px;
  width: 50%;
  transition: all 500ms ease-in-out;
}

.more-button.more-opened {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg)
}

footer{
  margin-top: 100px;
  font-family: "Quarca-Light", sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  height: 12em;
  color: #FFF;
  font-size: 1.1em;
  background: #4BF;
    background: -webkit-linear-gradient(22deg, #0FF, #57F);
}

footer a{
  color:inherit;
  text-decoration: none;
}

footer .fa {
  margin-right:20px;
}

.footer-minor{
  font-size: 0.7em;
}

.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
          filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\F26E"; }

.fa-accessible-icon:before {
  content: "\F368"; }

.fa-accusoft:before {
  content: "\F369"; }

.fa-acquisitions-incorporated:before {
  content: "\F6AF"; }

.fa-ad:before {
  content: "\F641"; }

.fa-address-book:before {
  content: "\F2B9"; }

.fa-address-card:before {
  content: "\F2BB"; }

.fa-adjust:before {
  content: "\F042"; }

.fa-adn:before {
  content: "\F170"; }

.fa-adobe:before {
  content: "\F778"; }

.fa-adversal:before {
  content: "\F36A"; }

.fa-affiliatetheme:before {
  content: "\F36B"; }

.fa-air-freshener:before {
  content: "\F5D0"; }

.fa-algolia:before {
  content: "\F36C"; }

.fa-align-center:before {
  content: "\F037"; }

.fa-align-justify:before {
  content: "\F039"; }

.fa-align-left:before {
  content: "\F036"; }

.fa-align-right:before {
  content: "\F038"; }

.fa-alipay:before {
  content: "\F642"; }

.fa-allergies:before {
  content: "\F461"; }

.fa-amazon:before {
  content: "\F270"; }

.fa-amazon-pay:before {
  content: "\F42C"; }

.fa-ambulance:before {
  content: "\F0F9"; }

.fa-american-sign-language-interpreting:before {
  content: "\F2A3"; }

.fa-amilia:before {
  content: "\F36D"; }

.fa-anchor:before {
  content: "\F13D"; }

.fa-android:before {
  content: "\F17B"; }

.fa-angellist:before {
  content: "\F209"; }

.fa-angle-double-down:before {
  content: "\F103"; }

.fa-angle-double-left:before {
  content: "\F100"; }

.fa-angle-double-right:before {
  content: "\F101"; }

.fa-angle-double-up:before {
  content: "\F102"; }

.fa-angle-down:before {
  content: "\F107"; }

.fa-angle-left:before {
  content: "\F104"; }

.fa-angle-right:before {
  content: "\F105"; }

.fa-angle-up:before {
  content: "\F106"; }

.fa-angry:before {
  content: "\F556"; }

.fa-angrycreative:before {
  content: "\F36E"; }

.fa-angular:before {
  content: "\F420"; }

.fa-ankh:before {
  content: "\F644"; }

.fa-app-store:before {
  content: "\F36F"; }

.fa-app-store-ios:before {
  content: "\F370"; }

.fa-apper:before {
  content: "\F371"; }

.fa-apple:before {
  content: "\F179"; }

.fa-apple-alt:before {
  content: "\F5D1"; }

.fa-apple-pay:before {
  content: "\F415"; }

.fa-archive:before {
  content: "\F187"; }

.fa-archway:before {
  content: "\F557"; }

.fa-arrow-alt-circle-down:before {
  content: "\F358"; }

.fa-arrow-alt-circle-left:before {
  content: "\F359"; }

.fa-arrow-alt-circle-right:before {
  content: "\F35A"; }

.fa-arrow-alt-circle-up:before {
  content: "\F35B"; }

.fa-arrow-circle-down:before {
  content: "\F0AB"; }

.fa-arrow-circle-left:before {
  content: "\F0A8"; }

.fa-arrow-circle-right:before {
  content: "\F0A9"; }

.fa-arrow-circle-up:before {
  content: "\F0AA"; }

.fa-arrow-down:before {
  content: "\F063"; }

.fa-arrow-left:before {
  content: "\F060"; }

.fa-arrow-right:before {
  content: "\F061"; }

.fa-arrow-up:before {
  content: "\F062"; }

.fa-arrows-alt:before {
  content: "\F0B2"; }

.fa-arrows-alt-h:before {
  content: "\F337"; }

.fa-arrows-alt-v:before {
  content: "\F338"; }

.fa-artstation:before {
  content: "\F77A"; }

.fa-assistive-listening-systems:before {
  content: "\F2A2"; }

.fa-asterisk:before {
  content: "\F069"; }

.fa-asymmetrik:before {
  content: "\F372"; }

.fa-at:before {
  content: "\F1FA"; }

.fa-atlas:before {
  content: "\F558"; }

.fa-atlassian:before {
  content: "\F77B"; }

.fa-atom:before {
  content: "\F5D2"; }

.fa-audible:before {
  content: "\F373"; }

.fa-audio-description:before {
  content: "\F29E"; }

.fa-autoprefixer:before {
  content: "\F41C"; }

.fa-avianex:before {
  content: "\F374"; }

.fa-aviato:before {
  content: "\F421"; }

.fa-award:before {
  content: "\F559"; }

.fa-aws:before {
  content: "\F375"; }

.fa-baby:before {
  content: "\F77C"; }

.fa-baby-carriage:before {
  content: "\F77D"; }

.fa-backspace:before {
  content: "\F55A"; }

.fa-backward:before {
  content: "\F04A"; }

.fa-bacon:before {
  content: "\F7E5"; }

.fa-balance-scale:before {
  content: "\F24E"; }

.fa-ban:before {
  content: "\F05E"; }

.fa-band-aid:before {
  content: "\F462"; }

.fa-bandcamp:before {
  content: "\F2D5"; }

.fa-barcode:before {
  content: "\F02A"; }

.fa-bars:before {
  content: "\F0C9"; }

.fa-baseball-ball:before {
  content: "\F433"; }

.fa-basketball-ball:before {
  content: "\F434"; }

.fa-bath:before {
  content: "\F2CD"; }

.fa-battery-empty:before {
  content: "\F244"; }

.fa-battery-full:before {
  content: "\F240"; }

.fa-battery-half:before {
  content: "\F242"; }

.fa-battery-quarter:before {
  content: "\F243"; }

.fa-battery-three-quarters:before {
  content: "\F241"; }

.fa-bed:before {
  content: "\F236"; }

.fa-beer:before {
  content: "\F0FC"; }

.fa-behance:before {
  content: "\F1B4"; }

.fa-behance-square:before {
  content: "\F1B5"; }

.fa-bell:before {
  content: "\F0F3"; }

.fa-bell-slash:before {
  content: "\F1F6"; }

.fa-bezier-curve:before {
  content: "\F55B"; }

.fa-bible:before {
  content: "\F647"; }

.fa-bicycle:before {
  content: "\F206"; }

.fa-bimobject:before {
  content: "\F378"; }

.fa-binoculars:before {
  content: "\F1E5"; }

.fa-biohazard:before {
  content: "\F780"; }

.fa-birthday-cake:before {
  content: "\F1FD"; }

.fa-bitbucket:before {
  content: "\F171"; }

.fa-bitcoin:before {
  content: "\F379"; }

.fa-bity:before {
  content: "\F37A"; }

.fa-black-tie:before {
  content: "\F27E"; }

.fa-blackberry:before {
  content: "\F37B"; }

.fa-blender:before {
  content: "\F517"; }

.fa-blender-phone:before {
  content: "\F6B6"; }

.fa-blind:before {
  content: "\F29D"; }

.fa-blog:before {
  content: "\F781"; }

.fa-blogger:before {
  content: "\F37C"; }

.fa-blogger-b:before {
  content: "\F37D"; }

.fa-bluetooth:before {
  content: "\F293"; }

.fa-bluetooth-b:before {
  content: "\F294"; }

.fa-bold:before {
  content: "\F032"; }

.fa-bolt:before {
  content: "\F0E7"; }

.fa-bomb:before {
  content: "\F1E2"; }

.fa-bone:before {
  content: "\F5D7"; }

.fa-bong:before {
  content: "\F55C"; }

.fa-book:before {
  content: "\F02D"; }

.fa-book-dead:before {
  content: "\F6B7"; }

.fa-book-medical:before {
  content: "\F7E6"; }

.fa-book-open:before {
  content: "\F518"; }

.fa-book-reader:before {
  content: "\F5DA"; }

.fa-bookmark:before {
  content: "\F02E"; }

.fa-bowling-ball:before {
  content: "\F436"; }

.fa-box:before {
  content: "\F466"; }

.fa-box-open:before {
  content: "\F49E"; }

.fa-boxes:before {
  content: "\F468"; }

.fa-braille:before {
  content: "\F2A1"; }

.fa-brain:before {
  content: "\F5DC"; }

.fa-bread-slice:before {
  content: "\F7EC"; }

.fa-briefcase:before {
  content: "\F0B1"; }

.fa-briefcase-medical:before {
  content: "\F469"; }

.fa-broadcast-tower:before {
  content: "\F519"; }

.fa-broom:before {
  content: "\F51A"; }

.fa-brush:before {
  content: "\F55D"; }

.fa-btc:before {
  content: "\F15A"; }

.fa-bug:before {
  content: "\F188"; }

.fa-building:before {
  content: "\F1AD"; }

.fa-bullhorn:before {
  content: "\F0A1"; }

.fa-bullseye:before {
  content: "\F140"; }

.fa-burn:before {
  content: "\F46A"; }

.fa-buromobelexperte:before {
  content: "\F37F"; }

.fa-bus:before {
  content: "\F207"; }

.fa-bus-alt:before {
  content: "\F55E"; }

.fa-business-time:before {
  content: "\F64A"; }

.fa-buysellads:before {
  content: "\F20D"; }

.fa-calculator:before {
  content: "\F1EC"; }

.fa-calendar:before {
  content: "\F133"; }

.fa-calendar-alt:before {
  content: "\F073"; }

.fa-calendar-check:before {
  content: "\F274"; }

.fa-calendar-day:before {
  content: "\F783"; }

.fa-calendar-minus:before {
  content: "\F272"; }

.fa-calendar-plus:before {
  content: "\F271"; }

.fa-calendar-times:before {
  content: "\F273"; }

.fa-calendar-week:before {
  content: "\F784"; }

.fa-camera:before {
  content: "\F030"; }

.fa-camera-retro:before {
  content: "\F083"; }

.fa-campground:before {
  content: "\F6BB"; }

.fa-canadian-maple-leaf:before {
  content: "\F785"; }

.fa-candy-cane:before {
  content: "\F786"; }

.fa-cannabis:before {
  content: "\F55F"; }

.fa-capsules:before {
  content: "\F46B"; }

.fa-car:before {
  content: "\F1B9"; }

.fa-car-alt:before {
  content: "\F5DE"; }

.fa-car-battery:before {
  content: "\F5DF"; }

.fa-car-crash:before {
  content: "\F5E1"; }

.fa-car-side:before {
  content: "\F5E4"; }

.fa-caret-down:before {
  content: "\F0D7"; }

.fa-caret-left:before {
  content: "\F0D9"; }

.fa-caret-right:before {
  content: "\F0DA"; }

.fa-caret-square-down:before {
  content: "\F150"; }

.fa-caret-square-left:before {
  content: "\F191"; }

.fa-caret-square-right:before {
  content: "\F152"; }

.fa-caret-square-up:before {
  content: "\F151"; }

.fa-caret-up:before {
  content: "\F0D8"; }

.fa-carrot:before {
  content: "\F787"; }

.fa-cart-arrow-down:before {
  content: "\F218"; }

.fa-cart-plus:before {
  content: "\F217"; }

.fa-cash-register:before {
  content: "\F788"; }

.fa-cat:before {
  content: "\F6BE"; }

.fa-cc-amazon-pay:before {
  content: "\F42D"; }

.fa-cc-amex:before {
  content: "\F1F3"; }

.fa-cc-apple-pay:before {
  content: "\F416"; }

.fa-cc-diners-club:before {
  content: "\F24C"; }

.fa-cc-discover:before {
  content: "\F1F2"; }

.fa-cc-jcb:before {
  content: "\F24B"; }

.fa-cc-mastercard:before {
  content: "\F1F1"; }

.fa-cc-paypal:before {
  content: "\F1F4"; }

.fa-cc-stripe:before {
  content: "\F1F5"; }

.fa-cc-visa:before {
  content: "\F1F0"; }

.fa-centercode:before {
  content: "\F380"; }

.fa-centos:before {
  content: "\F789"; }

.fa-certificate:before {
  content: "\F0A3"; }

.fa-chair:before {
  content: "\F6C0"; }

.fa-chalkboard:before {
  content: "\F51B"; }

.fa-chalkboard-teacher:before {
  content: "\F51C"; }

.fa-charging-station:before {
  content: "\F5E7"; }

.fa-chart-area:before {
  content: "\F1FE"; }

.fa-chart-bar:before {
  content: "\F080"; }

.fa-chart-line:before {
  content: "\F201"; }

.fa-chart-pie:before {
  content: "\F200"; }

.fa-check:before {
  content: "\F00C"; }

.fa-check-circle:before {
  content: "\F058"; }

.fa-check-double:before {
  content: "\F560"; }

.fa-check-square:before {
  content: "\F14A"; }

.fa-cheese:before {
  content: "\F7EF"; }

.fa-chess:before {
  content: "\F439"; }

.fa-chess-bishop:before {
  content: "\F43A"; }

.fa-chess-board:before {
  content: "\F43C"; }

.fa-chess-king:before {
  content: "\F43F"; }

.fa-chess-knight:before {
  content: "\F441"; }

.fa-chess-pawn:before {
  content: "\F443"; }

.fa-chess-queen:before {
  content: "\F445"; }

.fa-chess-rook:before {
  content: "\F447"; }

.fa-chevron-circle-down:before {
  content: "\F13A"; }

.fa-chevron-circle-left:before {
  content: "\F137"; }

.fa-chevron-circle-right:before {
  content: "\F138"; }

.fa-chevron-circle-up:before {
  content: "\F139"; }

.fa-chevron-down:before {
  content: "\F078"; }

.fa-chevron-left:before {
  content: "\F053"; }

.fa-chevron-right:before {
  content: "\F054"; }

.fa-chevron-up:before {
  content: "\F077"; }

.fa-child:before {
  content: "\F1AE"; }

.fa-chrome:before {
  content: "\F268"; }

.fa-church:before {
  content: "\F51D"; }

.fa-circle:before {
  content: "\F111"; }

.fa-circle-notch:before {
  content: "\F1CE"; }

.fa-city:before {
  content: "\F64F"; }

.fa-clinic-medical:before {
  content: "\F7F2"; }

.fa-clipboard:before {
  content: "\F328"; }

.fa-clipboard-check:before {
  content: "\F46C"; }

.fa-clipboard-list:before {
  content: "\F46D"; }

.fa-clock:before {
  content: "\F017"; }

.fa-clone:before {
  content: "\F24D"; }

.fa-closed-captioning:before {
  content: "\F20A"; }

.fa-cloud:before {
  content: "\F0C2"; }

.fa-cloud-download-alt:before {
  content: "\F381"; }

.fa-cloud-meatball:before {
  content: "\F73B"; }

.fa-cloud-moon:before {
  content: "\F6C3"; }

.fa-cloud-moon-rain:before {
  content: "\F73C"; }

.fa-cloud-rain:before {
  content: "\F73D"; }

.fa-cloud-showers-heavy:before {
  content: "\F740"; }

.fa-cloud-sun:before {
  content: "\F6C4"; }

.fa-cloud-sun-rain:before {
  content: "\F743"; }

.fa-cloud-upload-alt:before {
  content: "\F382"; }

.fa-cloudscale:before {
  content: "\F383"; }

.fa-cloudsmith:before {
  content: "\F384"; }

.fa-cloudversify:before {
  content: "\F385"; }

.fa-cocktail:before {
  content: "\F561"; }

.fa-code:before {
  content: "\F121"; }

.fa-code-branch:before {
  content: "\F126"; }

.fa-codepen:before {
  content: "\F1CB"; }

.fa-codiepie:before {
  content: "\F284"; }

.fa-coffee:before {
  content: "\F0F4"; }

.fa-cog:before {
  content: "\F013"; }

.fa-cogs:before {
  content: "\F085"; }

.fa-coins:before {
  content: "\F51E"; }

.fa-columns:before {
  content: "\F0DB"; }

.fa-comment:before {
  content: "\F075"; }

.fa-comment-alt:before {
  content: "\F27A"; }

.fa-comment-dollar:before {
  content: "\F651"; }

.fa-comment-dots:before {
  content: "\F4AD"; }

.fa-comment-medical:before {
  content: "\F7F5"; }

.fa-comment-slash:before {
  content: "\F4B3"; }

.fa-comments:before {
  content: "\F086"; }

.fa-comments-dollar:before {
  content: "\F653"; }

.fa-compact-disc:before {
  content: "\F51F"; }

.fa-compass:before {
  content: "\F14E"; }

.fa-compress:before {
  content: "\F066"; }

.fa-compress-arrows-alt:before {
  content: "\F78C"; }

.fa-concierge-bell:before {
  content: "\F562"; }

.fa-confluence:before {
  content: "\F78D"; }

.fa-connectdevelop:before {
  content: "\F20E"; }

.fa-contao:before {
  content: "\F26D"; }

.fa-cookie:before {
  content: "\F563"; }

.fa-cookie-bite:before {
  content: "\F564"; }

.fa-copy:before {
  content: "\F0C5"; }

.fa-copyright:before {
  content: "\F1F9"; }

.fa-couch:before {
  content: "\F4B8"; }

.fa-cpanel:before {
  content: "\F388"; }

.fa-creative-commons:before {
  content: "\F25E"; }

.fa-creative-commons-by:before {
  content: "\F4E7"; }

.fa-creative-commons-nc:before {
  content: "\F4E8"; }

.fa-creative-commons-nc-eu:before {
  content: "\F4E9"; }

.fa-creative-commons-nc-jp:before {
  content: "\F4EA"; }

.fa-creative-commons-nd:before {
  content: "\F4EB"; }

.fa-creative-commons-pd:before {
  content: "\F4EC"; }

.fa-creative-commons-pd-alt:before {
  content: "\F4ED"; }

.fa-creative-commons-remix:before {
  content: "\F4EE"; }

.fa-creative-commons-sa:before {
  content: "\F4EF"; }

.fa-creative-commons-sampling:before {
  content: "\F4F0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\F4F1"; }

.fa-creative-commons-share:before {
  content: "\F4F2"; }

.fa-creative-commons-zero:before {
  content: "\F4F3"; }

.fa-credit-card:before {
  content: "\F09D"; }

.fa-critical-role:before {
  content: "\F6C9"; }

.fa-crop:before {
  content: "\F125"; }

.fa-crop-alt:before {
  content: "\F565"; }

.fa-cross:before {
  content: "\F654"; }

.fa-crosshairs:before {
  content: "\F05B"; }

.fa-crow:before {
  content: "\F520"; }

.fa-crown:before {
  content: "\F521"; }

.fa-crutch:before {
  content: "\F7F7"; }

.fa-css3:before {
  content: "\F13C"; }

.fa-css3-alt:before {
  content: "\F38B"; }

.fa-cube:before {
  content: "\F1B2"; }

.fa-cubes:before {
  content: "\F1B3"; }

.fa-cut:before {
  content: "\F0C4"; }

.fa-cuttlefish:before {
  content: "\F38C"; }

.fa-d-and-d:before {
  content: "\F38D"; }

.fa-d-and-d-beyond:before {
  content: "\F6CA"; }

.fa-dashcube:before {
  content: "\F210"; }

.fa-database:before {
  content: "\F1C0"; }

.fa-deaf:before {
  content: "\F2A4"; }

.fa-delicious:before {
  content: "\F1A5"; }

.fa-democrat:before {
  content: "\F747"; }

.fa-deploydog:before {
  content: "\F38E"; }

.fa-deskpro:before {
  content: "\F38F"; }

.fa-desktop:before {
  content: "\F108"; }

.fa-dev:before {
  content: "\F6CC"; }

.fa-deviantart:before {
  content: "\F1BD"; }

.fa-dharmachakra:before {
  content: "\F655"; }

.fa-dhl:before {
  content: "\F790"; }

.fa-diagnoses:before {
  content: "\F470"; }

.fa-diaspora:before {
  content: "\F791"; }

.fa-dice:before {
  content: "\F522"; }

.fa-dice-d20:before {
  content: "\F6CF"; }

.fa-dice-d6:before {
  content: "\F6D1"; }

.fa-dice-five:before {
  content: "\F523"; }

.fa-dice-four:before {
  content: "\F524"; }

.fa-dice-one:before {
  content: "\F525"; }

.fa-dice-six:before {
  content: "\F526"; }

.fa-dice-three:before {
  content: "\F527"; }

.fa-dice-two:before {
  content: "\F528"; }

.fa-digg:before {
  content: "\F1A6"; }

.fa-digital-ocean:before {
  content: "\F391"; }

.fa-digital-tachograph:before {
  content: "\F566"; }

.fa-directions:before {
  content: "\F5EB"; }

.fa-discord:before {
  content: "\F392"; }

.fa-discourse:before {
  content: "\F393"; }

.fa-divide:before {
  content: "\F529"; }

.fa-dizzy:before {
  content: "\F567"; }

.fa-dna:before {
  content: "\F471"; }

.fa-dochub:before {
  content: "\F394"; }

.fa-docker:before {
  content: "\F395"; }

.fa-dog:before {
  content: "\F6D3"; }

.fa-dollar-sign:before {
  content: "\F155"; }

.fa-dolly:before {
  content: "\F472"; }

.fa-dolly-flatbed:before {
  content: "\F474"; }

.fa-donate:before {
  content: "\F4B9"; }

.fa-door-closed:before {
  content: "\F52A"; }

.fa-door-open:before {
  content: "\F52B"; }

.fa-dot-circle:before {
  content: "\F192"; }

.fa-dove:before {
  content: "\F4BA"; }

.fa-download:before {
  content: "\F019"; }

.fa-draft2digital:before {
  content: "\F396"; }

.fa-drafting-compass:before {
  content: "\F568"; }

.fa-dragon:before {
  content: "\F6D5"; }

.fa-draw-polygon:before {
  content: "\F5EE"; }

.fa-dribbble:before {
  content: "\F17D"; }

.fa-dribbble-square:before {
  content: "\F397"; }

.fa-dropbox:before {
  content: "\F16B"; }

.fa-drum:before {
  content: "\F569"; }

.fa-drum-steelpan:before {
  content: "\F56A"; }

.fa-drumstick-bite:before {
  content: "\F6D7"; }

.fa-drupal:before {
  content: "\F1A9"; }

.fa-dumbbell:before {
  content: "\F44B"; }

.fa-dumpster:before {
  content: "\F793"; }

.fa-dumpster-fire:before {
  content: "\F794"; }

.fa-dungeon:before {
  content: "\F6D9"; }

.fa-dyalog:before {
  content: "\F399"; }

.fa-earlybirds:before {
  content: "\F39A"; }

.fa-ebay:before {
  content: "\F4F4"; }

.fa-edge:before {
  content: "\F282"; }

.fa-edit:before {
  content: "\F044"; }

.fa-egg:before {
  content: "\F7FB"; }

.fa-eject:before {
  content: "\F052"; }

.fa-elementor:before {
  content: "\F430"; }

.fa-ellipsis-h:before {
  content: "\F141"; }

.fa-ellipsis-v:before {
  content: "\F142"; }

.fa-ello:before {
  content: "\F5F1"; }

.fa-ember:before {
  content: "\F423"; }

.fa-empire:before {
  content: "\F1D1"; }

.fa-envelope:before {
  content: "\F0E0"; }

.fa-envelope-open:before {
  content: "\F2B6"; }

.fa-envelope-open-text:before {
  content: "\F658"; }

.fa-envelope-square:before {
  content: "\F199"; }

.fa-envira:before {
  content: "\F299"; }

.fa-equals:before {
  content: "\F52C"; }

.fa-eraser:before {
  content: "\F12D"; }

.fa-erlang:before {
  content: "\F39D"; }

.fa-ethereum:before {
  content: "\F42E"; }

.fa-ethernet:before {
  content: "\F796"; }

.fa-etsy:before {
  content: "\F2D7"; }

.fa-euro-sign:before {
  content: "\F153"; }

.fa-exchange-alt:before {
  content: "\F362"; }

.fa-exclamation:before {
  content: "\F12A"; }

.fa-exclamation-circle:before {
  content: "\F06A"; }

.fa-exclamation-triangle:before {
  content: "\F071"; }

.fa-expand:before {
  content: "\F065"; }

.fa-expand-arrows-alt:before {
  content: "\F31E"; }

.fa-expeditedssl:before {
  content: "\F23E"; }

.fa-external-link-alt:before {
  content: "\F35D"; }

.fa-external-link-square-alt:before {
  content: "\F360"; }

.fa-eye:before {
  content: "\F06E"; }

.fa-eye-dropper:before {
  content: "\F1FB"; }

.fa-eye-slash:before {
  content: "\F070"; }

.fa-facebook:before {
  content: "\F09A"; }

.fa-facebook-f:before {
  content: "\F39E"; }

.fa-facebook-messenger:before {
  content: "\F39F"; }

.fa-facebook-square:before {
  content: "\F082"; }

.fa-fantasy-flight-games:before {
  content: "\F6DC"; }

.fa-fast-backward:before {
  content: "\F049"; }

.fa-fast-forward:before {
  content: "\F050"; }

.fa-fax:before {
  content: "\F1AC"; }

.fa-feather:before {
  content: "\F52D"; }

.fa-feather-alt:before {
  content: "\F56B"; }

.fa-fedex:before {
  content: "\F797"; }

.fa-fedora:before {
  content: "\F798"; }

.fa-female:before {
  content: "\F182"; }

.fa-fighter-jet:before {
  content: "\F0FB"; }

.fa-figma:before {
  content: "\F799"; }

.fa-file:before {
  content: "\F15B"; }

.fa-file-alt:before {
  content: "\F15C"; }

.fa-file-archive:before {
  content: "\F1C6"; }

.fa-file-audio:before {
  content: "\F1C7"; }

.fa-file-code:before {
  content: "\F1C9"; }

.fa-file-contract:before {
  content: "\F56C"; }

.fa-file-csv:before {
  content: "\F6DD"; }

.fa-file-download:before {
  content: "\F56D"; }

.fa-file-excel:before {
  content: "\F1C3"; }

.fa-file-export:before {
  content: "\F56E"; }

.fa-file-image:before {
  content: "\F1C5"; }

.fa-file-import:before {
  content: "\F56F"; }

.fa-file-invoice:before {
  content: "\F570"; }

.fa-file-invoice-dollar:before {
  content: "\F571"; }

.fa-file-medical:before {
  content: "\F477"; }

.fa-file-medical-alt:before {
  content: "\F478"; }

.fa-file-pdf:before {
  content: "\F1C1"; }

.fa-file-powerpoint:before {
  content: "\F1C4"; }

.fa-file-prescription:before {
  content: "\F572"; }

.fa-file-signature:before {
  content: "\F573"; }

.fa-file-upload:before {
  content: "\F574"; }

.fa-file-video:before {
  content: "\F1C8"; }

.fa-file-word:before {
  content: "\F1C2"; }

.fa-fill:before {
  content: "\F575"; }

.fa-fill-drip:before {
  content: "\F576"; }

.fa-film:before {
  content: "\F008"; }

.fa-filter:before {
  content: "\F0B0"; }

.fa-fingerprint:before {
  content: "\F577"; }

.fa-fire:before {
  content: "\F06D"; }

.fa-fire-alt:before {
  content: "\F7E4"; }

.fa-fire-extinguisher:before {
  content: "\F134"; }

.fa-firefox:before {
  content: "\F269"; }

.fa-first-aid:before {
  content: "\F479"; }

.fa-first-order:before {
  content: "\F2B0"; }

.fa-first-order-alt:before {
  content: "\F50A"; }

.fa-firstdraft:before {
  content: "\F3A1"; }

.fa-fish:before {
  content: "\F578"; }

.fa-fist-raised:before {
  content: "\F6DE"; }

.fa-flag:before {
  content: "\F024"; }

.fa-flag-checkered:before {
  content: "\F11E"; }

.fa-flag-usa:before {
  content: "\F74D"; }

.fa-flask:before {
  content: "\F0C3"; }

.fa-flickr:before {
  content: "\F16E"; }

.fa-flipboard:before {
  content: "\F44D"; }

.fa-flushed:before {
  content: "\F579"; }

.fa-fly:before {
  content: "\F417"; }

.fa-folder:before {
  content: "\F07B"; }

.fa-folder-minus:before {
  content: "\F65D"; }

.fa-folder-open:before {
  content: "\F07C"; }

.fa-folder-plus:before {
  content: "\F65E"; }

.fa-font:before {
  content: "\F031"; }

.fa-font-awesome:before {
  content: "\F2B4"; }

.fa-font-awesome-alt:before {
  content: "\F35C"; }

.fa-font-awesome-flag:before {
  content: "\F425"; }

.fa-font-awesome-logo-full:before {
  content: "\F4E6"; }

.fa-fonticons:before {
  content: "\F280"; }

.fa-fonticons-fi:before {
  content: "\F3A2"; }

.fa-football-ball:before {
  content: "\F44E"; }

.fa-fort-awesome:before {
  content: "\F286"; }

.fa-fort-awesome-alt:before {
  content: "\F3A3"; }

.fa-forumbee:before {
  content: "\F211"; }

.fa-forward:before {
  content: "\F04E"; }

.fa-foursquare:before {
  content: "\F180"; }

.fa-free-code-camp:before {
  content: "\F2C5"; }

.fa-freebsd:before {
  content: "\F3A4"; }

.fa-frog:before {
  content: "\F52E"; }

.fa-frown:before {
  content: "\F119"; }

.fa-frown-open:before {
  content: "\F57A"; }

.fa-fulcrum:before {
  content: "\F50B"; }

.fa-funnel-dollar:before {
  content: "\F662"; }

.fa-futbol:before {
  content: "\F1E3"; }

.fa-galactic-republic:before {
  content: "\F50C"; }

.fa-galactic-senate:before {
  content: "\F50D"; }

.fa-gamepad:before {
  content: "\F11B"; }

.fa-gas-pump:before {
  content: "\F52F"; }

.fa-gavel:before {
  content: "\F0E3"; }

.fa-gem:before {
  content: "\F3A5"; }

.fa-genderless:before {
  content: "\F22D"; }

.fa-get-pocket:before {
  content: "\F265"; }

.fa-gg:before {
  content: "\F260"; }

.fa-gg-circle:before {
  content: "\F261"; }

.fa-ghost:before {
  content: "\F6E2"; }

.fa-gift:before {
  content: "\F06B"; }

.fa-gifts:before {
  content: "\F79C"; }

.fa-git:before {
  content: "\F1D3"; }

.fa-git-square:before {
  content: "\F1D2"; }

.fa-github:before {
  content: "\F09B"; }

.fa-github-alt:before {
  content: "\F113"; }

.fa-github-square:before {
  content: "\F092"; }

.fa-gitkraken:before {
  content: "\F3A6"; }

.fa-gitlab:before {
  content: "\F296"; }

.fa-gitter:before {
  content: "\F426"; }

.fa-glass-cheers:before {
  content: "\F79F"; }

.fa-glass-martini:before {
  content: "\F000"; }

.fa-glass-martini-alt:before {
  content: "\F57B"; }

.fa-glass-whiskey:before {
  content: "\F7A0"; }

.fa-glasses:before {
  content: "\F530"; }

.fa-glide:before {
  content: "\F2A5"; }

.fa-glide-g:before {
  content: "\F2A6"; }

.fa-globe:before {
  content: "\F0AC"; }

.fa-globe-africa:before {
  content: "\F57C"; }

.fa-globe-americas:before {
  content: "\F57D"; }

.fa-globe-asia:before {
  content: "\F57E"; }

.fa-globe-europe:before {
  content: "\F7A2"; }

.fa-gofore:before {
  content: "\F3A7"; }

.fa-golf-ball:before {
  content: "\F450"; }

.fa-goodreads:before {
  content: "\F3A8"; }

.fa-goodreads-g:before {
  content: "\F3A9"; }

.fa-google:before {
  content: "\F1A0"; }

.fa-google-drive:before {
  content: "\F3AA"; }

.fa-google-play:before {
  content: "\F3AB"; }

.fa-google-plus:before {
  content: "\F2B3"; }

.fa-google-plus-g:before {
  content: "\F0D5"; }

.fa-google-plus-square:before {
  content: "\F0D4"; }

.fa-google-wallet:before {
  content: "\F1EE"; }

.fa-gopuram:before {
  content: "\F664"; }

.fa-graduation-cap:before {
  content: "\F19D"; }

.fa-gratipay:before {
  content: "\F184"; }

.fa-grav:before {
  content: "\F2D6"; }

.fa-greater-than:before {
  content: "\F531"; }

.fa-greater-than-equal:before {
  content: "\F532"; }

.fa-grimace:before {
  content: "\F57F"; }

.fa-grin:before {
  content: "\F580"; }

.fa-grin-alt:before {
  content: "\F581"; }

.fa-grin-beam:before {
  content: "\F582"; }

.fa-grin-beam-sweat:before {
  content: "\F583"; }

.fa-grin-hearts:before {
  content: "\F584"; }

.fa-grin-squint:before {
  content: "\F585"; }

.fa-grin-squint-tears:before {
  content: "\F586"; }

.fa-grin-stars:before {
  content: "\F587"; }

.fa-grin-tears:before {
  content: "\F588"; }

.fa-grin-tongue:before {
  content: "\F589"; }

.fa-grin-tongue-squint:before {
  content: "\F58A"; }

.fa-grin-tongue-wink:before {
  content: "\F58B"; }

.fa-grin-wink:before {
  content: "\F58C"; }

.fa-grip-horizontal:before {
  content: "\F58D"; }

.fa-grip-lines:before {
  content: "\F7A4"; }

.fa-grip-lines-vertical:before {
  content: "\F7A5"; }

.fa-grip-vertical:before {
  content: "\F58E"; }

.fa-gripfire:before {
  content: "\F3AC"; }

.fa-grunt:before {
  content: "\F3AD"; }

.fa-guitar:before {
  content: "\F7A6"; }

.fa-gulp:before {
  content: "\F3AE"; }

.fa-h-square:before {
  content: "\F0FD"; }

.fa-hacker-news:before {
  content: "\F1D4"; }

.fa-hacker-news-square:before {
  content: "\F3AF"; }

.fa-hackerrank:before {
  content: "\F5F7"; }

.fa-hamburger:before {
  content: "\F805"; }

.fa-hammer:before {
  content: "\F6E3"; }

.fa-hamsa:before {
  content: "\F665"; }

.fa-hand-holding:before {
  content: "\F4BD"; }

.fa-hand-holding-heart:before {
  content: "\F4BE"; }

.fa-hand-holding-usd:before {
  content: "\F4C0"; }

.fa-hand-lizard:before {
  content: "\F258"; }

.fa-hand-middle-finger:before {
  content: "\F806"; }

.fa-hand-paper:before {
  content: "\F256"; }

.fa-hand-peace:before {
  content: "\F25B"; }

.fa-hand-point-down:before {
  content: "\F0A7"; }

.fa-hand-point-left:before {
  content: "\F0A5"; }

.fa-hand-point-right:before {
  content: "\F0A4"; }

.fa-hand-point-up:before {
  content: "\F0A6"; }

.fa-hand-pointer:before {
  content: "\F25A"; }

.fa-hand-rock:before {
  content: "\F255"; }

.fa-hand-scissors:before {
  content: "\F257"; }

.fa-hand-spock:before {
  content: "\F259"; }

.fa-hands:before {
  content: "\F4C2"; }

.fa-hands-helping:before {
  content: "\F4C4"; }

.fa-handshake:before {
  content: "\F2B5"; }

.fa-hanukiah:before {
  content: "\F6E6"; }

.fa-hard-hat:before {
  content: "\F807"; }

.fa-hashtag:before {
  content: "\F292"; }

.fa-hat-wizard:before {
  content: "\F6E8"; }

.fa-haykal:before {
  content: "\F666"; }

.fa-hdd:before {
  content: "\F0A0"; }

.fa-heading:before {
  content: "\F1DC"; }

.fa-headphones:before {
  content: "\F025"; }

.fa-headphones-alt:before {
  content: "\F58F"; }

.fa-headset:before {
  content: "\F590"; }

.fa-heart:before {
  content: "\F004"; }

.fa-heart-broken:before {
  content: "\F7A9"; }

.fa-heartbeat:before {
  content: "\F21E"; }

.fa-helicopter:before {
  content: "\F533"; }

.fa-highlighter:before {
  content: "\F591"; }

.fa-hiking:before {
  content: "\F6EC"; }

.fa-hippo:before {
  content: "\F6ED"; }

.fa-hips:before {
  content: "\F452"; }

.fa-hire-a-helper:before {
  content: "\F3B0"; }

.fa-history:before {
  content: "\F1DA"; }

.fa-hockey-puck:before {
  content: "\F453"; }

.fa-holly-berry:before {
  content: "\F7AA"; }

.fa-home:before {
  content: "\F015"; }

.fa-hooli:before {
  content: "\F427"; }

.fa-hornbill:before {
  content: "\F592"; }

.fa-horse:before {
  content: "\F6F0"; }

.fa-horse-head:before {
  content: "\F7AB"; }

.fa-hospital:before {
  content: "\F0F8"; }

.fa-hospital-alt:before {
  content: "\F47D"; }

.fa-hospital-symbol:before {
  content: "\F47E"; }

.fa-hot-tub:before {
  content: "\F593"; }

.fa-hotdog:before {
  content: "\F80F"; }

.fa-hotel:before {
  content: "\F594"; }

.fa-hotjar:before {
  content: "\F3B1"; }

.fa-hourglass:before {
  content: "\F254"; }

.fa-hourglass-end:before {
  content: "\F253"; }

.fa-hourglass-half:before {
  content: "\F252"; }

.fa-hourglass-start:before {
  content: "\F251"; }

.fa-house-damage:before {
  content: "\F6F1"; }

.fa-houzz:before {
  content: "\F27C"; }

.fa-hryvnia:before {
  content: "\F6F2"; }

.fa-html5:before {
  content: "\F13B"; }

.fa-hubspot:before {
  content: "\F3B2"; }

.fa-i-cursor:before {
  content: "\F246"; }

.fa-ice-cream:before {
  content: "\F810"; }

.fa-icicles:before {
  content: "\F7AD"; }

.fa-id-badge:before {
  content: "\F2C1"; }

.fa-id-card:before {
  content: "\F2C2"; }

.fa-id-card-alt:before {
  content: "\F47F"; }

.fa-igloo:before {
  content: "\F7AE"; }

.fa-image:before {
  content: "\F03E"; }

.fa-images:before {
  content: "\F302"; }

.fa-imdb:before {
  content: "\F2D8"; }

.fa-inbox:before {
  content: "\F01C"; }

.fa-indent:before {
  content: "\F03C"; }

.fa-industry:before {
  content: "\F275"; }

.fa-infinity:before {
  content: "\F534"; }

.fa-info:before {
  content: "\F129"; }

.fa-info-circle:before {
  content: "\F05A"; }

.fa-instagram:before {
  content: "\F16D"; }

.fa-intercom:before {
  content: "\F7AF"; }

.fa-internet-explorer:before {
  content: "\F26B"; }

.fa-invision:before {
  content: "\F7B0"; }

.fa-ioxhost:before {
  content: "\F208"; }

.fa-italic:before {
  content: "\F033"; }

.fa-itunes:before {
  content: "\F3B4"; }

.fa-itunes-note:before {
  content: "\F3B5"; }

.fa-java:before {
  content: "\F4E4"; }

.fa-jedi:before {
  content: "\F669"; }

.fa-jedi-order:before {
  content: "\F50E"; }

.fa-jenkins:before {
  content: "\F3B6"; }

.fa-jira:before {
  content: "\F7B1"; }

.fa-joget:before {
  content: "\F3B7"; }

.fa-joint:before {
  content: "\F595"; }

.fa-joomla:before {
  content: "\F1AA"; }

.fa-journal-whills:before {
  content: "\F66A"; }

.fa-js:before {
  content: "\F3B8"; }

.fa-js-square:before {
  content: "\F3B9"; }

.fa-jsfiddle:before {
  content: "\F1CC"; }

.fa-kaaba:before {
  content: "\F66B"; }

.fa-kaggle:before {
  content: "\F5FA"; }

.fa-key:before {
  content: "\F084"; }

.fa-keybase:before {
  content: "\F4F5"; }

.fa-keyboard:before {
  content: "\F11C"; }

.fa-keycdn:before {
  content: "\F3BA"; }

.fa-khanda:before {
  content: "\F66D"; }

.fa-kickstarter:before {
  content: "\F3BB"; }

.fa-kickstarter-k:before {
  content: "\F3BC"; }

.fa-kiss:before {
  content: "\F596"; }

.fa-kiss-beam:before {
  content: "\F597"; }

.fa-kiss-wink-heart:before {
  content: "\F598"; }

.fa-kiwi-bird:before {
  content: "\F535"; }

.fa-korvue:before {
  content: "\F42F"; }

.fa-landmark:before {
  content: "\F66F"; }

.fa-language:before {
  content: "\F1AB"; }

.fa-laptop:before {
  content: "\F109"; }

.fa-laptop-code:before {
  content: "\F5FC"; }

.fa-laptop-medical:before {
  content: "\F812"; }

.fa-laravel:before {
  content: "\F3BD"; }

.fa-lastfm:before {
  content: "\F202"; }

.fa-lastfm-square:before {
  content: "\F203"; }

.fa-laugh:before {
  content: "\F599"; }

.fa-laugh-beam:before {
  content: "\F59A"; }

.fa-laugh-squint:before {
  content: "\F59B"; }

.fa-laugh-wink:before {
  content: "\F59C"; }

.fa-layer-group:before {
  content: "\F5FD"; }

.fa-leaf:before {
  content: "\F06C"; }

.fa-leanpub:before {
  content: "\F212"; }

.fa-lemon:before {
  content: "\F094"; }

.fa-less:before {
  content: "\F41D"; }

.fa-less-than:before {
  content: "\F536"; }

.fa-less-than-equal:before {
  content: "\F537"; }

.fa-level-down-alt:before {
  content: "\F3BE"; }

.fa-level-up-alt:before {
  content: "\F3BF"; }

.fa-life-ring:before {
  content: "\F1CD"; }

.fa-lightbulb:before {
  content: "\F0EB"; }

.fa-line:before {
  content: "\F3C0"; }

.fa-link:before {
  content: "\F0C1"; }

.fa-linkedin:before {
  content: "\F08C"; }

.fa-linkedin-in:before {
  content: "\F0E1"; }

.fa-linode:before {
  content: "\F2B8"; }

.fa-linux:before {
  content: "\F17C"; }

.fa-lira-sign:before {
  content: "\F195"; }

.fa-list:before {
  content: "\F03A"; }

.fa-list-alt:before {
  content: "\F022"; }

.fa-list-ol:before {
  content: "\F0CB"; }

.fa-list-ul:before {
  content: "\F0CA"; }

.fa-location-arrow:before {
  content: "\F124"; }

.fa-lock:before {
  content: "\F023"; }

.fa-lock-open:before {
  content: "\F3C1"; }

.fa-long-arrow-alt-down:before {
  content: "\F309"; }

.fa-long-arrow-alt-left:before {
  content: "\F30A"; }

.fa-long-arrow-alt-right:before {
  content: "\F30B"; }

.fa-long-arrow-alt-up:before {
  content: "\F30C"; }

.fa-low-vision:before {
  content: "\F2A8"; }

.fa-luggage-cart:before {
  content: "\F59D"; }

.fa-lyft:before {
  content: "\F3C3"; }

.fa-magento:before {
  content: "\F3C4"; }

.fa-magic:before {
  content: "\F0D0"; }

.fa-magnet:before {
  content: "\F076"; }

.fa-mail-bulk:before {
  content: "\F674"; }

.fa-mailchimp:before {
  content: "\F59E"; }

.fa-male:before {
  content: "\F183"; }

.fa-mandalorian:before {
  content: "\F50F"; }

.fa-map:before {
  content: "\F279"; }

.fa-map-marked:before {
  content: "\F59F"; }

.fa-map-marked-alt:before {
  content: "\F5A0"; }

.fa-map-marker:before {
  content: "\F041"; }

.fa-map-marker-alt:before {
  content: "\F3C5"; }

.fa-map-pin:before {
  content: "\F276"; }

.fa-map-signs:before {
  content: "\F277"; }

.fa-markdown:before {
  content: "\F60F"; }

.fa-marker:before {
  content: "\F5A1"; }

.fa-mars:before {
  content: "\F222"; }

.fa-mars-double:before {
  content: "\F227"; }

.fa-mars-stroke:before {
  content: "\F229"; }

.fa-mars-stroke-h:before {
  content: "\F22B"; }

.fa-mars-stroke-v:before {
  content: "\F22A"; }

.fa-mask:before {
  content: "\F6FA"; }

.fa-mastodon:before {
  content: "\F4F6"; }

.fa-maxcdn:before {
  content: "\F136"; }

.fa-medal:before {
  content: "\F5A2"; }

.fa-medapps:before {
  content: "\F3C6"; }

.fa-medium:before {
  content: "\F23A"; }

.fa-medium-m:before {
  content: "\F3C7"; }

.fa-medkit:before {
  content: "\F0FA"; }

.fa-medrt:before {
  content: "\F3C8"; }

.fa-meetup:before {
  content: "\F2E0"; }

.fa-megaport:before {
  content: "\F5A3"; }

.fa-meh:before {
  content: "\F11A"; }

.fa-meh-blank:before {
  content: "\F5A4"; }

.fa-meh-rolling-eyes:before {
  content: "\F5A5"; }

.fa-memory:before {
  content: "\F538"; }

.fa-mendeley:before {
  content: "\F7B3"; }

.fa-menorah:before {
  content: "\F676"; }

.fa-mercury:before {
  content: "\F223"; }

.fa-meteor:before {
  content: "\F753"; }

.fa-microchip:before {
  content: "\F2DB"; }

.fa-microphone:before {
  content: "\F130"; }

.fa-microphone-alt:before {
  content: "\F3C9"; }

.fa-microphone-alt-slash:before {
  content: "\F539"; }

.fa-microphone-slash:before {
  content: "\F131"; }

.fa-microscope:before {
  content: "\F610"; }

.fa-microsoft:before {
  content: "\F3CA"; }

.fa-minus:before {
  content: "\F068"; }

.fa-minus-circle:before {
  content: "\F056"; }

.fa-minus-square:before {
  content: "\F146"; }

.fa-mitten:before {
  content: "\F7B5"; }

.fa-mix:before {
  content: "\F3CB"; }

.fa-mixcloud:before {
  content: "\F289"; }

.fa-mizuni:before {
  content: "\F3CC"; }

.fa-mobile:before {
  content: "\F10B"; }

.fa-mobile-alt:before {
  content: "\F3CD"; }

.fa-modx:before {
  content: "\F285"; }

.fa-monero:before {
  content: "\F3D0"; }

.fa-money-bill:before {
  content: "\F0D6"; }

.fa-money-bill-alt:before {
  content: "\F3D1"; }

.fa-money-bill-wave:before {
  content: "\F53A"; }

.fa-money-bill-wave-alt:before {
  content: "\F53B"; }

.fa-money-check:before {
  content: "\F53C"; }

.fa-money-check-alt:before {
  content: "\F53D"; }

.fa-monument:before {
  content: "\F5A6"; }

.fa-moon:before {
  content: "\F186"; }

.fa-mortar-pestle:before {
  content: "\F5A7"; }

.fa-mosque:before {
  content: "\F678"; }

.fa-motorcycle:before {
  content: "\F21C"; }

.fa-mountain:before {
  content: "\F6FC"; }

.fa-mouse-pointer:before {
  content: "\F245"; }

.fa-mug-hot:before {
  content: "\F7B6"; }

.fa-music:before {
  content: "\F001"; }

.fa-napster:before {
  content: "\F3D2"; }

.fa-neos:before {
  content: "\F612"; }

.fa-network-wired:before {
  content: "\F6FF"; }

.fa-neuter:before {
  content: "\F22C"; }

.fa-newspaper:before {
  content: "\F1EA"; }

.fa-nimblr:before {
  content: "\F5A8"; }

.fa-nintendo-switch:before {
  content: "\F418"; }

.fa-node:before {
  content: "\F419"; }

.fa-node-js:before {
  content: "\F3D3"; }

.fa-not-equal:before {
  content: "\F53E"; }

.fa-notes-medical:before {
  content: "\F481"; }

.fa-npm:before {
  content: "\F3D4"; }

.fa-ns8:before {
  content: "\F3D5"; }

.fa-nutritionix:before {
  content: "\F3D6"; }

.fa-object-group:before {
  content: "\F247"; }

.fa-object-ungroup:before {
  content: "\F248"; }

.fa-odnoklassniki:before {
  content: "\F263"; }

.fa-odnoklassniki-square:before {
  content: "\F264"; }

.fa-oil-can:before {
  content: "\F613"; }

.fa-old-republic:before {
  content: "\F510"; }

.fa-om:before {
  content: "\F679"; }

.fa-opencart:before {
  content: "\F23D"; }

.fa-openid:before {
  content: "\F19B"; }

.fa-opera:before {
  content: "\F26A"; }

.fa-optin-monster:before {
  content: "\F23C"; }

.fa-osi:before {
  content: "\F41A"; }

.fa-otter:before {
  content: "\F700"; }

.fa-outdent:before {
  content: "\F03B"; }

.fa-page4:before {
  content: "\F3D7"; }

.fa-pagelines:before {
  content: "\F18C"; }

.fa-pager:before {
  content: "\F815"; }

.fa-paint-brush:before {
  content: "\F1FC"; }

.fa-paint-roller:before {
  content: "\F5AA"; }

.fa-palette:before {
  content: "\F53F"; }

.fa-palfed:before {
  content: "\F3D8"; }

.fa-pallet:before {
  content: "\F482"; }

.fa-paper-plane:before {
  content: "\F1D8"; }

.fa-paperclip:before {
  content: "\F0C6"; }

.fa-parachute-box:before {
  content: "\F4CD"; }

.fa-paragraph:before {
  content: "\F1DD"; }

.fa-parking:before {
  content: "\F540"; }

.fa-passport:before {
  content: "\F5AB"; }

.fa-pastafarianism:before {
  content: "\F67B"; }

.fa-paste:before {
  content: "\F0EA"; }

.fa-patreon:before {
  content: "\F3D9"; }

.fa-pause:before {
  content: "\F04C"; }

.fa-pause-circle:before {
  content: "\F28B"; }

.fa-paw:before {
  content: "\F1B0"; }

.fa-paypal:before {
  content: "\F1ED"; }

.fa-peace:before {
  content: "\F67C"; }

.fa-pen:before {
  content: "\F304"; }

.fa-pen-alt:before {
  content: "\F305"; }

.fa-pen-fancy:before {
  content: "\F5AC"; }

.fa-pen-nib:before {
  content: "\F5AD"; }

.fa-pen-square:before {
  content: "\F14B"; }

.fa-pencil-alt:before {
  content: "\F303"; }

.fa-pencil-ruler:before {
  content: "\F5AE"; }

.fa-penny-arcade:before {
  content: "\F704"; }

.fa-people-carry:before {
  content: "\F4CE"; }

.fa-pepper-hot:before {
  content: "\F816"; }

.fa-percent:before {
  content: "\F295"; }

.fa-percentage:before {
  content: "\F541"; }

.fa-periscope:before {
  content: "\F3DA"; }

.fa-person-booth:before {
  content: "\F756"; }

.fa-phabricator:before {
  content: "\F3DB"; }

.fa-phoenix-framework:before {
  content: "\F3DC"; }

.fa-phoenix-squadron:before {
  content: "\F511"; }

.fa-phone:before {
  content: "\F095"; }

.fa-phone-slash:before {
  content: "\F3DD"; }

.fa-phone-square:before {
  content: "\F098"; }

.fa-phone-volume:before {
  content: "\F2A0"; }

.fa-php:before {
  content: "\F457"; }

.fa-pied-piper:before {
  content: "\F2AE"; }

.fa-pied-piper-alt:before {
  content: "\F1A8"; }

.fa-pied-piper-hat:before {
  content: "\F4E5"; }

.fa-pied-piper-pp:before {
  content: "\F1A7"; }

.fa-piggy-bank:before {
  content: "\F4D3"; }

.fa-pills:before {
  content: "\F484"; }

.fa-pinterest:before {
  content: "\F0D2"; }

.fa-pinterest-p:before {
  content: "\F231"; }

.fa-pinterest-square:before {
  content: "\F0D3"; }

.fa-pizza-slice:before {
  content: "\F818"; }

.fa-place-of-worship:before {
  content: "\F67F"; }

.fa-plane:before {
  content: "\F072"; }

.fa-plane-arrival:before {
  content: "\F5AF"; }

.fa-plane-departure:before {
  content: "\F5B0"; }

.fa-play:before {
  content: "\F04B"; }

.fa-play-circle:before {
  content: "\F144"; }

.fa-playstation:before {
  content: "\F3DF"; }

.fa-plug:before {
  content: "\F1E6"; }

.fa-plus:before {
  content: "\F067"; }

.fa-plus-circle:before {
  content: "\F055"; }

.fa-plus-square:before {
  content: "\F0FE"; }

.fa-podcast:before {
  content: "\F2CE"; }

.fa-poll:before {
  content: "\F681"; }

.fa-poll-h:before {
  content: "\F682"; }

.fa-poo:before {
  content: "\F2FE"; }

.fa-poo-storm:before {
  content: "\F75A"; }

.fa-poop:before {
  content: "\F619"; }

.fa-portrait:before {
  content: "\F3E0"; }

.fa-pound-sign:before {
  content: "\F154"; }

.fa-power-off:before {
  content: "\F011"; }

.fa-pray:before {
  content: "\F683"; }

.fa-praying-hands:before {
  content: "\F684"; }

.fa-prescription:before {
  content: "\F5B1"; }

.fa-prescription-bottle:before {
  content: "\F485"; }

.fa-prescription-bottle-alt:before {
  content: "\F486"; }

.fa-print:before {
  content: "\F02F"; }

.fa-procedures:before {
  content: "\F487"; }

.fa-product-hunt:before {
  content: "\F288"; }

.fa-project-diagram:before {
  content: "\F542"; }

.fa-pushed:before {
  content: "\F3E1"; }

.fa-puzzle-piece:before {
  content: "\F12E"; }

.fa-python:before {
  content: "\F3E2"; }

.fa-qq:before {
  content: "\F1D6"; }

.fa-qrcode:before {
  content: "\F029"; }

.fa-question:before {
  content: "\F128"; }

.fa-question-circle:before {
  content: "\F059"; }

.fa-quidditch:before {
  content: "\F458"; }

.fa-quinscape:before {
  content: "\F459"; }

.fa-quora:before {
  content: "\F2C4"; }

.fa-quote-left:before {
  content: "\F10D"; }

.fa-quote-right:before {
  content: "\F10E"; }

.fa-quran:before {
  content: "\F687"; }

.fa-r-project:before {
  content: "\F4F7"; }

.fa-radiation:before {
  content: "\F7B9"; }

.fa-radiation-alt:before {
  content: "\F7BA"; }

.fa-rainbow:before {
  content: "\F75B"; }

.fa-random:before {
  content: "\F074"; }

.fa-raspberry-pi:before {
  content: "\F7BB"; }

.fa-ravelry:before {
  content: "\F2D9"; }

.fa-react:before {
  content: "\F41B"; }

.fa-reacteurope:before {
  content: "\F75D"; }

.fa-readme:before {
  content: "\F4D5"; }

.fa-rebel:before {
  content: "\F1D0"; }

.fa-receipt:before {
  content: "\F543"; }

.fa-recycle:before {
  content: "\F1B8"; }

.fa-red-river:before {
  content: "\F3E3"; }

.fa-reddit:before {
  content: "\F1A1"; }

.fa-reddit-alien:before {
  content: "\F281"; }

.fa-reddit-square:before {
  content: "\F1A2"; }

.fa-redhat:before {
  content: "\F7BC"; }

.fa-redo:before {
  content: "\F01E"; }

.fa-redo-alt:before {
  content: "\F2F9"; }

.fa-registered:before {
  content: "\F25D"; }

.fa-renren:before {
  content: "\F18B"; }

.fa-reply:before {
  content: "\F3E5"; }

.fa-reply-all:before {
  content: "\F122"; }

.fa-replyd:before {
  content: "\F3E6"; }

.fa-republican:before {
  content: "\F75E"; }

.fa-researchgate:before {
  content: "\F4F8"; }

.fa-resolving:before {
  content: "\F3E7"; }

.fa-restroom:before {
  content: "\F7BD"; }

.fa-retweet:before {
  content: "\F079"; }

.fa-rev:before {
  content: "\F5B2"; }

.fa-ribbon:before {
  content: "\F4D6"; }

.fa-ring:before {
  content: "\F70B"; }

.fa-road:before {
  content: "\F018"; }

.fa-robot:before {
  content: "\F544"; }

.fa-rocket:before {
  content: "\F135"; }

.fa-rocketchat:before {
  content: "\F3E8"; }

.fa-rockrms:before {
  content: "\F3E9"; }

.fa-route:before {
  content: "\F4D7"; }

.fa-rss:before {
  content: "\F09E"; }

.fa-rss-square:before {
  content: "\F143"; }

.fa-ruble-sign:before {
  content: "\F158"; }

.fa-ruler:before {
  content: "\F545"; }

.fa-ruler-combined:before {
  content: "\F546"; }

.fa-ruler-horizontal:before {
  content: "\F547"; }

.fa-ruler-vertical:before {
  content: "\F548"; }

.fa-running:before {
  content: "\F70C"; }

.fa-rupee-sign:before {
  content: "\F156"; }

.fa-sad-cry:before {
  content: "\F5B3"; }

.fa-sad-tear:before {
  content: "\F5B4"; }

.fa-safari:before {
  content: "\F267"; }

.fa-sass:before {
  content: "\F41E"; }

.fa-satellite:before {
  content: "\F7BF"; }

.fa-satellite-dish:before {
  content: "\F7C0"; }

.fa-save:before {
  content: "\F0C7"; }

.fa-schlix:before {
  content: "\F3EA"; }

.fa-school:before {
  content: "\F549"; }

.fa-screwdriver:before {
  content: "\F54A"; }

.fa-scribd:before {
  content: "\F28A"; }

.fa-scroll:before {
  content: "\F70E"; }

.fa-sd-card:before {
  content: "\F7C2"; }

.fa-search:before {
  content: "\F002"; }

.fa-search-dollar:before {
  content: "\F688"; }

.fa-search-location:before {
  content: "\F689"; }

.fa-search-minus:before {
  content: "\F010"; }

.fa-search-plus:before {
  content: "\F00E"; }

.fa-searchengin:before {
  content: "\F3EB"; }

.fa-seedling:before {
  content: "\F4D8"; }

.fa-sellcast:before {
  content: "\F2DA"; }

.fa-sellsy:before {
  content: "\F213"; }

.fa-server:before {
  content: "\F233"; }

.fa-servicestack:before {
  content: "\F3EC"; }

.fa-shapes:before {
  content: "\F61F"; }

.fa-share:before {
  content: "\F064"; }

.fa-share-alt:before {
  content: "\F1E0"; }

.fa-share-alt-square:before {
  content: "\F1E1"; }

.fa-share-square:before {
  content: "\F14D"; }

.fa-shekel-sign:before {
  content: "\F20B"; }

.fa-shield-alt:before {
  content: "\F3ED"; }

.fa-ship:before {
  content: "\F21A"; }

.fa-shipping-fast:before {
  content: "\F48B"; }

.fa-shirtsinbulk:before {
  content: "\F214"; }

.fa-shoe-prints:before {
  content: "\F54B"; }

.fa-shopping-bag:before {
  content: "\F290"; }

.fa-shopping-basket:before {
  content: "\F291"; }

.fa-shopping-cart:before {
  content: "\F07A"; }

.fa-shopware:before {
  content: "\F5B5"; }

.fa-shower:before {
  content: "\F2CC"; }

.fa-shuttle-van:before {
  content: "\F5B6"; }

.fa-sign:before {
  content: "\F4D9"; }

.fa-sign-in-alt:before {
  content: "\F2F6"; }

.fa-sign-language:before {
  content: "\F2A7"; }

.fa-sign-out-alt:before {
  content: "\F2F5"; }

.fa-signal:before {
  content: "\F012"; }

.fa-signature:before {
  content: "\F5B7"; }

.fa-sim-card:before {
  content: "\F7C4"; }

.fa-simplybuilt:before {
  content: "\F215"; }

.fa-sistrix:before {
  content: "\F3EE"; }

.fa-sitemap:before {
  content: "\F0E8"; }

.fa-sith:before {
  content: "\F512"; }

.fa-skating:before {
  content: "\F7C5"; }

.fa-sketch:before {
  content: "\F7C6"; }

.fa-skiing:before {
  content: "\F7C9"; }

.fa-skiing-nordic:before {
  content: "\F7CA"; }

.fa-skull:before {
  content: "\F54C"; }

.fa-skull-crossbones:before {
  content: "\F714"; }

.fa-skyatlas:before {
  content: "\F216"; }

.fa-skype:before {
  content: "\F17E"; }

.fa-slack:before {
  content: "\F198"; }

.fa-slack-hash:before {
  content: "\F3EF"; }

.fa-slash:before {
  content: "\F715"; }

.fa-sleigh:before {
  content: "\F7CC"; }

.fa-sliders-h:before {
  content: "\F1DE"; }

.fa-slideshare:before {
  content: "\F1E7"; }

.fa-smile:before {
  content: "\F118"; }

.fa-smile-beam:before {
  content: "\F5B8"; }

.fa-smile-wink:before {
  content: "\F4DA"; }

.fa-smog:before {
  content: "\F75F"; }

.fa-smoking:before {
  content: "\F48D"; }

.fa-smoking-ban:before {
  content: "\F54D"; }

.fa-sms:before {
  content: "\F7CD"; }

.fa-snapchat:before {
  content: "\F2AB"; }

.fa-snapchat-ghost:before {
  content: "\F2AC"; }

.fa-snapchat-square:before {
  content: "\F2AD"; }

.fa-snowboarding:before {
  content: "\F7CE"; }

.fa-snowflake:before {
  content: "\F2DC"; }

.fa-snowman:before {
  content: "\F7D0"; }

.fa-snowplow:before {
  content: "\F7D2"; }

.fa-socks:before {
  content: "\F696"; }

.fa-solar-panel:before {
  content: "\F5BA"; }

.fa-sort:before {
  content: "\F0DC"; }

.fa-sort-alpha-down:before {
  content: "\F15D"; }

.fa-sort-alpha-up:before {
  content: "\F15E"; }

.fa-sort-amount-down:before {
  content: "\F160"; }

.fa-sort-amount-up:before {
  content: "\F161"; }

.fa-sort-down:before {
  content: "\F0DD"; }

.fa-sort-numeric-down:before {
  content: "\F162"; }

.fa-sort-numeric-up:before {
  content: "\F163"; }

.fa-sort-up:before {
  content: "\F0DE"; }

.fa-soundcloud:before {
  content: "\F1BE"; }

.fa-sourcetree:before {
  content: "\F7D3"; }

.fa-spa:before {
  content: "\F5BB"; }

.fa-space-shuttle:before {
  content: "\F197"; }

.fa-speakap:before {
  content: "\F3F3"; }

.fa-spider:before {
  content: "\F717"; }

.fa-spinner:before {
  content: "\F110"; }

.fa-splotch:before {
  content: "\F5BC"; }

.fa-spotify:before {
  content: "\F1BC"; }

.fa-spray-can:before {
  content: "\F5BD"; }

.fa-square:before {
  content: "\F0C8"; }

.fa-square-full:before {
  content: "\F45C"; }

.fa-square-root-alt:before {
  content: "\F698"; }

.fa-squarespace:before {
  content: "\F5BE"; }

.fa-stack-exchange:before {
  content: "\F18D"; }

.fa-stack-overflow:before {
  content: "\F16C"; }

.fa-stamp:before {
  content: "\F5BF"; }

.fa-star:before {
  content: "\F005"; }

.fa-star-and-crescent:before {
  content: "\F699"; }

.fa-star-half:before {
  content: "\F089"; }

.fa-star-half-alt:before {
  content: "\F5C0"; }

.fa-star-of-david:before {
  content: "\F69A"; }

.fa-star-of-life:before {
  content: "\F621"; }

.fa-staylinked:before {
  content: "\F3F5"; }

.fa-steam:before {
  content: "\F1B6"; }

.fa-steam-square:before {
  content: "\F1B7"; }

.fa-steam-symbol:before {
  content: "\F3F6"; }

.fa-step-backward:before {
  content: "\F048"; }

.fa-step-forward:before {
  content: "\F051"; }

.fa-stethoscope:before {
  content: "\F0F1"; }

.fa-sticker-mule:before {
  content: "\F3F7"; }

.fa-sticky-note:before {
  content: "\F249"; }

.fa-stop:before {
  content: "\F04D"; }

.fa-stop-circle:before {
  content: "\F28D"; }

.fa-stopwatch:before {
  content: "\F2F2"; }

.fa-store:before {
  content: "\F54E"; }

.fa-store-alt:before {
  content: "\F54F"; }

.fa-strava:before {
  content: "\F428"; }

.fa-stream:before {
  content: "\F550"; }

.fa-street-view:before {
  content: "\F21D"; }

.fa-strikethrough:before {
  content: "\F0CC"; }

.fa-stripe:before {
  content: "\F429"; }

.fa-stripe-s:before {
  content: "\F42A"; }

.fa-stroopwafel:before {
  content: "\F551"; }

.fa-studiovinari:before {
  content: "\F3F8"; }

.fa-stumbleupon:before {
  content: "\F1A4"; }

.fa-stumbleupon-circle:before {
  content: "\F1A3"; }

.fa-subscript:before {
  content: "\F12C"; }

.fa-subway:before {
  content: "\F239"; }

.fa-suitcase:before {
  content: "\F0F2"; }

.fa-suitcase-rolling:before {
  content: "\F5C1"; }

.fa-sun:before {
  content: "\F185"; }

.fa-superpowers:before {
  content: "\F2DD"; }

.fa-superscript:before {
  content: "\F12B"; }

.fa-supple:before {
  content: "\F3F9"; }

.fa-surprise:before {
  content: "\F5C2"; }

.fa-suse:before {
  content: "\F7D6"; }

.fa-swatchbook:before {
  content: "\F5C3"; }

.fa-swimmer:before {
  content: "\F5C4"; }

.fa-swimming-pool:before {
  content: "\F5C5"; }

.fa-synagogue:before {
  content: "\F69B"; }

.fa-sync:before {
  content: "\F021"; }

.fa-sync-alt:before {
  content: "\F2F1"; }

.fa-syringe:before {
  content: "\F48E"; }

.fa-table:before {
  content: "\F0CE"; }

.fa-table-tennis:before {
  content: "\F45D"; }

.fa-tablet:before {
  content: "\F10A"; }

.fa-tablet-alt:before {
  content: "\F3FA"; }

.fa-tablets:before {
  content: "\F490"; }

.fa-tachometer-alt:before {
  content: "\F3FD"; }

.fa-tag:before {
  content: "\F02B"; }

.fa-tags:before {
  content: "\F02C"; }

.fa-tape:before {
  content: "\F4DB"; }

.fa-tasks:before {
  content: "\F0AE"; }

.fa-taxi:before {
  content: "\F1BA"; }

.fa-teamspeak:before {
  content: "\F4F9"; }

.fa-teeth:before {
  content: "\F62E"; }

.fa-teeth-open:before {
  content: "\F62F"; }

.fa-telegram:before {
  content: "\F2C6"; }

.fa-telegram-plane:before {
  content: "\F3FE"; }

.fa-temperature-high:before {
  content: "\F769"; }

.fa-temperature-low:before {
  content: "\F76B"; }

.fa-tencent-weibo:before {
  content: "\F1D5"; }

.fa-tenge:before {
  content: "\F7D7"; }

.fa-terminal:before {
  content: "\F120"; }

.fa-text-height:before {
  content: "\F034"; }

.fa-text-width:before {
  content: "\F035"; }

.fa-th:before {
  content: "\F00A"; }

.fa-th-large:before {
  content: "\F009"; }

.fa-th-list:before {
  content: "\F00B"; }

.fa-the-red-yeti:before {
  content: "\F69D"; }

.fa-theater-masks:before {
  content: "\F630"; }

.fa-themeco:before {
  content: "\F5C6"; }

.fa-themeisle:before {
  content: "\F2B2"; }

.fa-thermometer:before {
  content: "\F491"; }

.fa-thermometer-empty:before {
  content: "\F2CB"; }

.fa-thermometer-full:before {
  content: "\F2C7"; }

.fa-thermometer-half:before {
  content: "\F2C9"; }

.fa-thermometer-quarter:before {
  content: "\F2CA"; }

.fa-thermometer-three-quarters:before {
  content: "\F2C8"; }

.fa-think-peaks:before {
  content: "\F731"; }

.fa-thumbs-down:before {
  content: "\F165"; }

.fa-thumbs-up:before {
  content: "\F164"; }

.fa-thumbtack:before {
  content: "\F08D"; }

.fa-ticket-alt:before {
  content: "\F3FF"; }

.fa-times:before {
  content: "\F00D"; }

.fa-times-circle:before {
  content: "\F057"; }

.fa-tint:before {
  content: "\F043"; }

.fa-tint-slash:before {
  content: "\F5C7"; }

.fa-tired:before {
  content: "\F5C8"; }

.fa-toggle-off:before {
  content: "\F204"; }

.fa-toggle-on:before {
  content: "\F205"; }

.fa-toilet:before {
  content: "\F7D8"; }

.fa-toilet-paper:before {
  content: "\F71E"; }

.fa-toolbox:before {
  content: "\F552"; }

.fa-tools:before {
  content: "\F7D9"; }

.fa-tooth:before {
  content: "\F5C9"; }

.fa-torah:before {
  content: "\F6A0"; }

.fa-torii-gate:before {
  content: "\F6A1"; }

.fa-tractor:before {
  content: "\F722"; }

.fa-trade-federation:before {
  content: "\F513"; }

.fa-trademark:before {
  content: "\F25C"; }

.fa-traffic-light:before {
  content: "\F637"; }

.fa-train:before {
  content: "\F238"; }

.fa-tram:before {
  content: "\F7DA"; }

.fa-transgender:before {
  content: "\F224"; }

.fa-transgender-alt:before {
  content: "\F225"; }

.fa-trash:before {
  content: "\F1F8"; }

.fa-trash-alt:before {
  content: "\F2ED"; }

.fa-trash-restore:before {
  content: "\F829"; }

.fa-trash-restore-alt:before {
  content: "\F82A"; }

.fa-tree:before {
  content: "\F1BB"; }

.fa-trello:before {
  content: "\F181"; }

.fa-tripadvisor:before {
  content: "\F262"; }

.fa-trophy:before {
  content: "\F091"; }

.fa-truck:before {
  content: "\F0D1"; }

.fa-truck-loading:before {
  content: "\F4DE"; }

.fa-truck-monster:before {
  content: "\F63B"; }

.fa-truck-moving:before {
  content: "\F4DF"; }

.fa-truck-pickup:before {
  content: "\F63C"; }

.fa-tshirt:before {
  content: "\F553"; }

.fa-tty:before {
  content: "\F1E4"; }

.fa-tumblr:before {
  content: "\F173"; }

.fa-tumblr-square:before {
  content: "\F174"; }

.fa-tv:before {
  content: "\F26C"; }

.fa-twitch:before {
  content: "\F1E8"; }

.fa-twitter:before {
  content: "\F099"; }

.fa-twitter-square:before {
  content: "\F081"; }

.fa-typo3:before {
  content: "\F42B"; }

.fa-uber:before {
  content: "\F402"; }

.fa-ubuntu:before {
  content: "\F7DF"; }

.fa-uikit:before {
  content: "\F403"; }

.fa-umbrella:before {
  content: "\F0E9"; }

.fa-umbrella-beach:before {
  content: "\F5CA"; }

.fa-underline:before {
  content: "\F0CD"; }

.fa-undo:before {
  content: "\F0E2"; }

.fa-undo-alt:before {
  content: "\F2EA"; }

.fa-uniregistry:before {
  content: "\F404"; }

.fa-universal-access:before {
  content: "\F29A"; }

.fa-university:before {
  content: "\F19C"; }

.fa-unlink:before {
  content: "\F127"; }

.fa-unlock:before {
  content: "\F09C"; }

.fa-unlock-alt:before {
  content: "\F13E"; }

.fa-untappd:before {
  content: "\F405"; }

.fa-upload:before {
  content: "\F093"; }

.fa-ups:before {
  content: "\F7E0"; }

.fa-usb:before {
  content: "\F287"; }

.fa-user:before {
  content: "\F007"; }

.fa-user-alt:before {
  content: "\F406"; }

.fa-user-alt-slash:before {
  content: "\F4FA"; }

.fa-user-astronaut:before {
  content: "\F4FB"; }

.fa-user-check:before {
  content: "\F4FC"; }

.fa-user-circle:before {
  content: "\F2BD"; }

.fa-user-clock:before {
  content: "\F4FD"; }

.fa-user-cog:before {
  content: "\F4FE"; }

.fa-user-edit:before {
  content: "\F4FF"; }

.fa-user-friends:before {
  content: "\F500"; }

.fa-user-graduate:before {
  content: "\F501"; }

.fa-user-injured:before {
  content: "\F728"; }

.fa-user-lock:before {
  content: "\F502"; }

.fa-user-md:before {
  content: "\F0F0"; }

.fa-user-minus:before {
  content: "\F503"; }

.fa-user-ninja:before {
  content: "\F504"; }

.fa-user-nurse:before {
  content: "\F82F"; }

.fa-user-plus:before {
  content: "\F234"; }

.fa-user-secret:before {
  content: "\F21B"; }

.fa-user-shield:before {
  content: "\F505"; }

.fa-user-slash:before {
  content: "\F506"; }

.fa-user-tag:before {
  content: "\F507"; }

.fa-user-tie:before {
  content: "\F508"; }

.fa-user-times:before {
  content: "\F235"; }

.fa-users:before {
  content: "\F0C0"; }

.fa-users-cog:before {
  content: "\F509"; }

.fa-usps:before {
  content: "\F7E1"; }

.fa-ussunnah:before {
  content: "\F407"; }

.fa-utensil-spoon:before {
  content: "\F2E5"; }

.fa-utensils:before {
  content: "\F2E7"; }

.fa-vaadin:before {
  content: "\F408"; }

.fa-vector-square:before {
  content: "\F5CB"; }

.fa-venus:before {
  content: "\F221"; }

.fa-venus-double:before {
  content: "\F226"; }

.fa-venus-mars:before {
  content: "\F228"; }

.fa-viacoin:before {
  content: "\F237"; }

.fa-viadeo:before {
  content: "\F2A9"; }

.fa-viadeo-square:before {
  content: "\F2AA"; }

.fa-vial:before {
  content: "\F492"; }

.fa-vials:before {
  content: "\F493"; }

.fa-viber:before {
  content: "\F409"; }

.fa-video:before {
  content: "\F03D"; }

.fa-video-slash:before {
  content: "\F4E2"; }

.fa-vihara:before {
  content: "\F6A7"; }

.fa-vimeo:before {
  content: "\F40A"; }

.fa-vimeo-square:before {
  content: "\F194"; }

.fa-vimeo-v:before {
  content: "\F27D"; }

.fa-vine:before {
  content: "\F1CA"; }

.fa-vk:before {
  content: "\F189"; }

.fa-vnv:before {
  content: "\F40B"; }

.fa-volleyball-ball:before {
  content: "\F45F"; }

.fa-volume-down:before {
  content: "\F027"; }

.fa-volume-mute:before {
  content: "\F6A9"; }

.fa-volume-off:before {
  content: "\F026"; }

.fa-volume-up:before {
  content: "\F028"; }

.fa-vote-yea:before {
  content: "\F772"; }

.fa-vr-cardboard:before {
  content: "\F729"; }

.fa-vuejs:before {
  content: "\F41F"; }

.fa-walking:before {
  content: "\F554"; }

.fa-wallet:before {
  content: "\F555"; }

.fa-warehouse:before {
  content: "\F494"; }

.fa-water:before {
  content: "\F773"; }

.fa-weebly:before {
  content: "\F5CC"; }

.fa-weibo:before {
  content: "\F18A"; }

.fa-weight:before {
  content: "\F496"; }

.fa-weight-hanging:before {
  content: "\F5CD"; }

.fa-weixin:before {
  content: "\F1D7"; }

.fa-whatsapp:before {
  content: "\F232"; }

.fa-whatsapp-square:before {
  content: "\F40C"; }

.fa-wheelchair:before {
  content: "\F193"; }

.fa-whmcs:before {
  content: "\F40D"; }

.fa-wifi:before {
  content: "\F1EB"; }

.fa-wikipedia-w:before {
  content: "\F266"; }

.fa-wind:before {
  content: "\F72E"; }

.fa-window-close:before {
  content: "\F410"; }

.fa-window-maximize:before {
  content: "\F2D0"; }

.fa-window-minimize:before {
  content: "\F2D1"; }

.fa-window-restore:before {
  content: "\F2D2"; }

.fa-windows:before {
  content: "\F17A"; }

.fa-wine-bottle:before {
  content: "\F72F"; }

.fa-wine-glass:before {
  content: "\F4E3"; }

.fa-wine-glass-alt:before {
  content: "\F5CE"; }

.fa-wix:before {
  content: "\F5CF"; }

.fa-wizards-of-the-coast:before {
  content: "\F730"; }

.fa-wolf-pack-battalion:before {
  content: "\F514"; }

.fa-won-sign:before {
  content: "\F159"; }

.fa-wordpress:before {
  content: "\F19A"; }

.fa-wordpress-simple:before {
  content: "\F411"; }

.fa-wpbeginner:before {
  content: "\F297"; }

.fa-wpexplorer:before {
  content: "\F2DE"; }

.fa-wpforms:before {
  content: "\F298"; }

.fa-wpressr:before {
  content: "\F3E4"; }

.fa-wrench:before {
  content: "\F0AD"; }

.fa-x-ray:before {
  content: "\F497"; }

.fa-xbox:before {
  content: "\F412"; }

.fa-xing:before {
  content: "\F168"; }

.fa-xing-square:before {
  content: "\F169"; }

.fa-y-combinator:before {
  content: "\F23B"; }

.fa-yahoo:before {
  content: "\F19E"; }

.fa-yandex:before {
  content: "\F413"; }

.fa-yandex-international:before {
  content: "\F414"; }

.fa-yarn:before {
  content: "\F7E3"; }

.fa-yelp:before {
  content: "\F1E9"; }

.fa-yen-sign:before {
  content: "\F157"; }

.fa-yin-yang:before {
  content: "\F6AD"; }

.fa-yoast:before {
  content: "\F2B1"; }

.fa-youtube:before {
  content: "\F167"; }

.fa-youtube-square:before {
  content: "\F431"; }

.fa-zhihu:before {
  content: "\F63F"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url(/static/media/fa-brands-400.3186ebd2.eot);
  src: url(/static/media/fa-brands-400.3186ebd2.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-brands-400.662c24d0.woff2) format("woff2"), url(/static/media/fa-brands-400.c7d7a2a1.woff) format("woff"), url(/static/media/fa-brands-400.a995bae1.ttf) format("truetype"), url(/static/media/fa-brands-400.e4fed0a5.svg#fontawesome) format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(/static/media/fa-regular-400.80efa56b.eot);
  src: url(/static/media/fa-regular-400.80efa56b.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-regular-400.6a9d786e.woff2) format("woff2"), url(/static/media/fa-regular-400.72f15fa7.woff) format("woff"), url(/static/media/fa-regular-400.fcb220ee.ttf) format("truetype"), url(/static/media/fa-regular-400.304f31f4.svg#fontawesome) format("svg"); }

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(/static/media/fa-solid-900.9a52a4e9.eot);
  src: url(/static/media/fa-solid-900.9a52a4e9.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-solid-900.3638e62e.woff2) format("woff2"), url(/static/media/fa-solid-900.9c73abbd.woff) format("woff"), url(/static/media/fa-solid-900.20c189aa.ttf) format("truetype"), url(/static/media/fa-solid-900.c8ea4c79.svg#fontawesome) format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900; }

