.App {
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #FFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

body {
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #FFF;
}

@font-face {
  font-family: 'Quarca';
  font-style: normal;
  font-weight: 800;
  src: local('Quarca-Ext-Regular'),
       local('Quarca Ext Regular'),
       url('./Fonts/Quarca/QuarcaExtRegular.otf') format('opentype'); /* Modern Browsers */
}

@font-face {
  font-family: 'Quarca-Light';
  font-style: normal;
  font-weight: 800;
  src: local('Quarca-Ext-Light'),
       local('Quarca Ext Light'),
       url('./Fonts/Quarca/QuarcaNormRegular.otf') format('opentype'); /* Modern Browsers */
}

@font-face {
  font-family: 'Quarca-Fat';
  font-style: normal;
  font-weight: 800;
  src: local('Quarca-Ext-Fat'),
       local('Quarca Ext Fat'),
       url('./Fonts/Quarca/QuarcaNormMedium.otf') format('opentype'); /* Modern Browsers */
}

@font-face {
  font-family: 'Perfect Dark';
  font-style: normal;
  font-weight: 900;
  src: local('Perfect-Dark'),
       local('Perfect Dark'),
       url('./Fonts/pdark2.ttf') format('truetype'); /* Modern Browsers */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 1600px) {
  .nice-button-wrapper {
    font-size: 25px !important;
  }

  .nice-button {
    background: #FFF;
    padding: 20px !important;
    transition: 500ms all ease-in-out;
    width: 140px !important;
  }

  .nice-button-overlay {
    width: 140px !important;
  }

  .topBar {
    height: 140px !important;
  }
  .topBar:not(.hidebar){
    height: 110px !important;
  }
}

.nice-button-wrapper {
  padding: 1px;
  background-image: linear-gradient(22deg, #0FF, #57F);
  border: none;
  color: #555;
  display: grid;
  place-items: center;
  font-family: 'Quarca', sans-serif;
  margin-right: 48px;
  font-size: 18px;
}

.nice-button {
  background: #FFF;
  padding-top: 10px;
  padding-bottom: 10px;
    transition: 500ms all ease-in-out;
  width: 110px;
}

.nice-button-text {
  background: -webkit-linear-gradient(22deg, #0FF, #57F);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 500ms all ease-in-out;
  cursor: default;
  user-select: none;
}

.nice-button:hover {
  background: none;
  color: #FFF;
}

.nice-button:hover .nice-button-overlay {
  opacity: 1;
}

.nice-button-overlay {
  position: absolute;
  color: #FFF;
  opacity: 0;
  cursor: default;
  transition: 500ms all ease-in-out;
  width: 110px;
  user-select: none;
}

.topBar{
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFD;
  width: 100%;
  height: 118px;
  margin-top: -1px;
  position: fixed;
  z-index: 9999;
  top: 0;
  transition: all 500ms ease-in-out;
}

.topBar:hover:not(.hidebar) {
  background: #FFFF;
}

.topBar:not(.hidebar){
  height: 90px;
}

.repairSection{
  position:absolute;
  margin-top:-140px;
}

.topBar a {
  text-decoration: none;
  cursor: default;
}

.hidebar{
  background: none;
  opacity: 0.7
}

.hidebar .nice-button{
  background: none;
}
.hidebar .nice-button-wrapper{
  background: none;
}

.hidebar .nice-button-wrapper:hover .nice-button-overlay{
  text-shadow: 0px 0px 10px #3AF;
}


.hidebar:hover{
  opacity: 1;
}


.buttonsContainer {
  display: flex;
}

.logo {
  font-family: 'Perfect Dark', sans-serif;
  font-size: 2em;
  font-kerning: none;
  margin-left: 40px;
  color: #3AF;
  /* text-shadow: 0px 0px 50px #AAF; */
  user-select: none;
  transition: all 300ms ease-in-out;
}

.hidebar .logo {
  margin-top: -15px;
}

.logo:hover {
  color: #FFF;
  text-shadow: 0px 0px 30px #3AF;
}

.slide {
  width: 100%;
}

.carousel{
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.carButtons {
  font-family: 'Quarca', sans-serif;
  display: flex;
  justify-content: space-between;
  z-index: 999;
  position: absolute;
  width: 100%;
  color: #FFF;
  cursor: default;
  user-select: none;
}

.carbut {
  width: 2em;
  height: 2em;
  font-size: 2em;
  display: flex !important;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 3%;
  margin-right: 3%;
  opacity: 0.5;
  transition: all 300ms ease-in-out;
}

.carbut:hover {
  opacity: 1;
}

.flip {
  transform: rotate(180deg);
}

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pic {
  width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.inspic {
  width: 100%;
}

.plane-text {
  padding: 10%;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.plane{
  width: 100%;
  margin-top: 150px;
  font-family: "Quarca", sans-serif;
}

.maxiplane{
  transition: all 500ms ease-in-out;
  width: 100%;
}

.plane .title{
  font-size: 1.5em;
  display: flex;
  margin-top: -20px;
  justify-content: center;
}

.plane .subtitle{
  font-family: 'Quarca-Fat', sans-serif;
  font-size: 0.9em;
  padding-bottom: 60px;
}

.more-button-wrapper{
  display: flex;
    flex-direction: column;
    align-items: center;
}

.more-button {
  transition: all 300ms ease-in-out;
  width:10%;
  transform: rotate(90deg);
  cursor: default;
  user-select: none;
}

.more-text-wrapper {
  overflow: hidden;
  position: absolute;
  height: 0px;
  width: 50%;
  transition: all 500ms ease-in-out;
}

.more-button.more-opened {
  transform: rotate(-90deg)
}

footer{
  margin-top: 100px;
  font-family: "Quarca-Light", sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 12em;
  color: #FFF;
  font-size: 1.1em;
  background: #4BF;
    background: -webkit-linear-gradient(22deg, #0FF, #57F);
}

footer a{
  color:inherit;
  text-decoration: none;
}

footer .fa {
  margin-right:20px;
}

.footer-minor{
  font-size: 0.7em;
}
